import { styled } from '../theme-provider';
import { Icon } from '../icon';
import { Text } from '../text';
export const YollandaButton = styled.button`
  border: 0;
  background: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgba(226, 58, 140, 0.15);
  color: rgba(226, 58, 140, 1);
  transition: all 250ms ease;

  :hover {
    background: rgba(226, 58, 140, 0.25);
  }

  :focus,
  :focus-visible {
    outline: none;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 768px) {
    width: 40px;
    height: 40px;
    padding: 6px;
  }
`;

export const YollandaContext = styled.div`
  display: inline-flex;
  align-items: center;
  column-gap: 8px;
  padding: 8px 12px;
  margin: 8px;
  background: #f5f5f5;
  color: #9f9f9f;
  border-radius: 8px;
`;

export const YollandaInputWrapper = styled.div`
  position: relative;
`;

export const YollandaFormHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const YollandaInput = styled.input`
  width: 100%;
  border: none;
  font-size: 18px;
  color: #4a4a4a;
  padding-left: calc(24px + 16px + 16px);
  padding-right: 24px;
  padding-top: 8px;
  padding-bottom: 8px;

  &:placeholder-shown + button {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.15);
    pointer-events: none;
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  :focus,
  :focus-visible {
    outline: none;
  }
`;

export const YollandaInputIcon = styled(Icon)`
  position: absolute;
  left: 24px;
  color: rgba(0, 0, 0, 0.5);
  top: 50%;
  transform: translateY(-50%);
`;

export const SpinnerWrapper = styled.div`
  display: flex;

  align-items: center;
`;

export const YollandaLayerLayout = styled.div`
  width: 600px;
  max-width: 90vw;
  position: relative;
`;

export const YollandaForm = styled.form`
  position: sticky;
  top: 0;
  background-color: #fff;
  padding-bottom: 12px;
`;

export const YollandaMessageContainer = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 24px;
  overflow: auto;
  max-height: 70vh;

  @media (min-width: 768px) {
    max-height: 50vh;
  }
`;

export const YollandaLoadingText = styled(Text)`
  opacity: 0;
  transition: opacity 750ms ease;
  &.visible {
    opacity: 1;
  }
`;

export const YollandaLikeButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 24px 16px;
  column-gap: 12px;

  button {
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const YollandaLikeButtonsTooltipWrapper = styled.div`
  display: flex;
`;

export const YollandaBetaBadge = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background-color: #fff9eb;
  color: #eaa318;
  border-radius: 8px;
  padding: 8px 12px;
  text-transform: uppercase;
`;

export const YollandaSubmitButton = styled.button`
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  border: 0;
  color: #fff;
  background-color: #000;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 250ms ease-out;
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.15);
  }

  svg path {
    stroke-width: 2px;
  }
`;
