import moment from 'moment';
import { useRef } from 'react';
import styled, { css } from 'styled-components';
import { FormattedDate, FormattedMessage } from 'react-intl';

import {
  Icon,
  IconSize,
  StyledAttachmentIcon,
  StyledTd,
  StyledTr,
  Text,
  TextAlignment,
  TextSize,
  WhiteSpace
} from '@yarmill/components';

import { AttachmentStore } from '../attributes/mobx/attachment-store';
import { FileOverview } from './types';
import { useFilesOverviewStore } from './files-overview-store-context';
import { FileLink } from '../attributes/attachment/file-link';
import {
  getDiaryRouteLink,
  getIndexForDate,
  ROUTE_DATE_FORMAT
} from '../diary/utils';
import { useFocusedDayService } from '../diary/hooks';
import { FileIcon } from './file-icon';
import { Link } from '../components/link';
import { TagsContainer } from './components/tags-container';
import { Tag } from './components/tag';
import { EditButtons } from './edit-buttons';
import { observer } from 'mobx-react-lite';

export interface FileRowProps {
  readonly file: AttachmentStore;
}

const ExtraControlButtonsStyles = css`
  height: 100%;
`;

const AttachmentIcon = styled(StyledAttachmentIcon)`
  width: 24px;
  height: 24px;
`;

export const FileRow = observer(function FileRow(
  props: FileRowProps
): JSX.Element {
  const { file } = props;
  const filesOverviewStore = useFilesOverviewStore();
  const attachment = file.attachment as FileOverview;
  const focusedDayService = useFocusedDayService();
  const modulesRef = useRef<HTMLTableCellElement>(null);

  const setFocusedDay = (): void => {
    setTimeout(
      () =>
        focusedDayService.setFocusedDay(
          getIndexForDate(attachment.ReferenceDate)
        ),
      0
    );
  };

  function dateLinkTarget(): string {
    return getDiaryRouteLink({
      diaryType: attachment.Type === 'P' ? 'plan' : 'reality',
      viewType: 'week',
      week: moment(attachment.ReferenceDate).format(ROUTE_DATE_FORMAT),
      groupId: filesOverviewStore.groupId || 0,
      athleteId: filesOverviewStore?.athleteId || 0
    });
  }

  return (
    <StyledTr>
      <StyledTd>
        <FileLink file={attachment}>
          <AttachmentIcon fileType={attachment.FileType}>
            <Icon size={IconSize.s12}>
              <FileIcon fileType={attachment.FileType} />
            </Icon>
          </AttachmentIcon>
        </FileLink>
      </StyledTd>
      <StyledTd align="left">
        <FileLink file={attachment}>
          <Text size={TextSize.s12} inheritColor>
            {attachment.FileName}
          </Text>
        </FileLink>
      </StyledTd>
      <StyledTd align="left">
        <TagsContainer>
          {attachment.Tags?.map(tag => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </TagsContainer>
      </StyledTd>
      <StyledTd ref={modulesRef}>
        <Text size={TextSize.s12}>
          {attachment.Type === 'P' ? (
            <FormattedMessage id="filesOverview.tags.p" />
          ) : (
            <FormattedMessage id="filesOverview.tags.r" />
          )}
        </Text>
      </StyledTd>
      <StyledTd>
        <Link to={dateLinkTarget} onClick={setFocusedDay}>
          <Text
            size={TextSize.s12}
            textAlign={TextAlignment.right}
            inheritColor
          >
            <FormattedDate value={moment(attachment.ReferenceDate).toDate()} />
          </Text>
        </Link>
      </StyledTd>
      <StyledTd
        whiteSpace={WhiteSpace.noWrap}
        sticky={{ right: 0 }}
        extraStyles={ExtraControlButtonsStyles}
      >
        <EditButtons file={file} />
      </StyledTd>
    </StyledTr>
  );
});
