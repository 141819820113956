import { observer } from 'mobx-react-lite';
import { YollandaLikeButtonsWrapper } from '@yarmill/components';
import { FeedbackButton } from './feedback-button';
import { YollandaFeedback } from './types';
import { useSendFeedbackHandler } from './hooks/use-send-feedback-handler';

interface FeedbackButtonProps {
  readonly interactionId: string;
}
export const FeedbackButtons = observer(function FeedbackButtons({
  interactionId
}: FeedbackButtonProps) {
  const [sendFeedback, sentFeedback] = useSendFeedbackHandler(interactionId);
  const allFeedbackSent = sentFeedback === 3;

  return (
    <YollandaLikeButtonsWrapper>
      <FeedbackButton
        value={YollandaFeedback.like}
        isDisabled={sentFeedback === YollandaFeedback.like || allFeedbackSent}
        showTooltip={allFeedbackSent}
        sendFeedback={sendFeedback}
      />
      <FeedbackButton
        value={YollandaFeedback.dislike}
        isDisabled={
          sentFeedback === YollandaFeedback.dislike || allFeedbackSent
        }
        showTooltip={allFeedbackSent}
        sendFeedback={sendFeedback}
      />
    </YollandaLikeButtonsWrapper>
  );
});
